import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { s, colors, alpha } from '../../style'
import { navStyle, navLink, activeLink} from "./collection-tags.module.css"

export default function CollectionLinks() {

  const results = useStaticQuery(graphql`
  {
    collections: allShopifyCollection(sort: {order: DESC, fields: metafields___collection___id}) {
      nodes {
        id
        handle
        title
      }
    }
  }
`)

  const collections = results.collections.nodes

  return (
    <nav className={navStyle}>
      <Link
        key="All"
        className={navLink}
        to="/sklep/"
        activeClassName={activeLink}
      >
        Wszystkie
      </Link>
      {collections.map(({id, title, handle}) => (
        <Link
          key={id}
          className={navLink}
          to={`/sklep/${handle}`}
          activeClassName={activeLink}
        >
          {title}
        </Link>
      ))}
    </nav>
  )
}