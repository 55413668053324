import * as React from "react"
import { ProductCard } from "./ProductCard"
import { colors, s } from '../style'

// To optimize LCP we mark the first product card as eager so the image gets loaded faster
export function ProductListing({ products = [] }) {
  return (
    <div css={sShopWrapper}>
      {products.map((p, index) => (
        <ProductCard product={p} key={p.id} eager={index === 0} />
      ))}
    </div>
  )
}

const sShopWrapper = {
  width: '100%',
  // maxWidth: 1500,
  margin: 'auto',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '3rem',
  marginTop: '2rem',

  // padding: '0 2rem',
  // [s.md]: {
  //   padding: '0 4rem',
  // },
  [s.md_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.xs]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  'span' : {
    color: 'white'
  }
}